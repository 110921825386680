.am-icon {
  fill: currentColor;
  background-size: cover;
  width: 0.586667rem;
  height: 0.586667rem;
}

.am-icon-xxs {
  width: 0.4rem;
  height: 0.4rem;
}

.am-icon-xs {
  width: 0.48rem;
  height: 0.48rem;
}

.am-icon-sm {
  width: 0.56rem;
  height: 0.56rem;
}

.am-icon-md {
  width: 0.586667rem;
  height: 0.586667rem;
}

.am-icon-lg {
  width: 0.96rem;
  height: 0.96rem;
}

.am-icon-loading {
  animation: cirle-anim 1s linear infinite;
}

@keyframes cirle-anim {
  100% {
    transform: rotate(360deg);
  }
}